import React, { useEffect } from 'react';
import s from './InvestmentGrowthGraph.module.css';
import { Bar, Chart } from 'react-chartjs-2';
import { setMillion, round, getMaxValueOfArray, getMinValueOfArray } from '../../../../helpers/chart_helpers';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { COLORS } from '../../../../assets/styles/colors/colors';
Chart.register(ChartDataLabels);

export const InvestmentGrowthGraph = React.memo(props => {
  const { investment_growth_graph_data, unitGrowth } = props;
  const growth_reachArr = investment_growth_graph_data.datasets && investment_growth_graph_data.datasets[0].data;
  const maxValueArr = investment_growth_graph_data.datasets && investment_growth_graph_data.datasets.filter(item => item.type === 'bar');
  const maxValue =
    maxValueArr &&
    maxValueArr.reduce(function (accumulator, currentValue) {
      return accumulator + +currentValue.data[8];
    }, 0);
  const visibleMaxValue = 5;
  const maxValuePr = (maxValue / 100) * visibleMaxValue; //вычисление процентов
  const options = {
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 4,
      },
    },
    plugins: {
      datalabels: {
        display: 'true',
        formatter: function (value) {
          if (+value > maxValuePr) {
            const changedValue = unitGrowth === '₽' ? round(setMillion(value), 1) : round(value, 1);
            return unitGrowth === '₽' ? changedValue : changedValue + ` ${unitGrowth}`;
          } else {
            return '';
          }
        },
        labels: {
          title: {
            font: {
              weight: 'bold',
              size: '14px',
            },
          },
        },
        anchor: 'centre',
        align: 'centre',
        color: function (context) {
          if (context.dataset.backgroundColor === COLORS.BannersImage || context.dataset.backgroundColor === COLORS.Bloggers || context.dataset.backgroundColor === COLORS.OOHBigFormatsSupersites || context.dataset.backgroundColor === COLORS.OOHCityformats || context.dataset.backgroundColor === COLORS.Radio || context.dataset.backgroundColor === COLORS.TV || context.dataset.backgroundColor === COLORS.OOHGroup || context.dataset.backgroundColor === COLORS.RadioGroup || context.dataset.backgroundColor === COLORS.TVGroup) {
            return 'white';
          } else {
            return 'black';
          }
        },
      },
      legend: {
        display: false,
        position: 'bottom',
        align: 'centre',
        usePointStyle: true,
      },
      tooltip: {
        enabled: true,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        titleColor: 'black',
        bodyColor: 'black',
      },
    },
    scales: {
      x: {
        stacked: true,
      },

      y: {
        max: unitGrowth === '%' ? 100 : null,
        stacked: true,
        title: {
          display: true,
          text: 'Затраты на медиа (млн)',
          color: 'grey',
          font: {
            size: 13,
          },

          padding: { top: 20, left: 0, right: 0, bottom: 0 },
        },
        ticks: {
          callback: function (val, index) {
            return unitGrowth === '₽' ? round(setMillion(val), 1) : round(val, 1);
          },
        },
      },
      ohvat: {
        min: getMinValueOfArray(growth_reachArr),
        max: getMaxValueOfArray(growth_reachArr),
        position: 'right',
        stacked: true,
        grid: { lineWidth: 0 },
        title: {
          display: true,
          text: 'Совокупный охват',
          color: 'grey',
          font: {
            size: 13,
          },
          padding: { top: 20, left: 0, right: 0, bottom: 0 },
        },
        ticks: {
          callback: function (val, index) {
            return this.getLabelForValue(round(val, 1)) + '%';
          },
        },
      },
    },
  };
  useEffect(() => {
    investment_growth_graph_data.datasets && investment_growth_graph_data.datasets.map(item => (document.getElementById(item.label).style.backgroundColor = item.backgroundColor));
  }, [investment_growth_graph_data]);

  return (
    <div>
      <div className={s.wrapper}>
        <Bar data={investment_growth_graph_data} options={options} width={1000} height={210} />
      </div>
      <div className={s.lenendsBox}>
        {investment_growth_graph_data.datasets &&
          investment_growth_graph_data.datasets.map(item => (
            <div className={s.legendWrapper} key={item.label}>
              <div id={item.label} className={item.label === 'Охват' ? s.ohvatLegend : s.legend}></div> <span>{item.label}</span>
            </div>
          ))}
      </div>
    </div>
  );
});
