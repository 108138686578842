import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import s from './MediaChoiceTable.module.css';
import { Checkbox } from '../../Common/Checkbox/Checkbox';
import { EditableSpan } from '../../Common/EditableSpan/EditableSpan';
import { setAllMedia, setMediaIsSelected, setGroupCheckedArr, setCopySelectedMedia, setInputDataChanged, setIsMonthVisible, setMonth, setCustomData } from '../../../../bll/inputParameters_reducer';
import { setMainMedia, setIsChartsUpload } from '../../../../bll/charts_reducer';
import { makeGroupsNames, findFirstMediaInGroup, findQuestion } from '../../../../helpers/mediaChoiceTable_helpers';

export const MediaChoiceTable = React.memo(props => {
  const { media } = props;
  const groups = makeGroupsNames(media);
  const firsMediaInGroupArr = findFirstMediaInGroup(groups, media);
  const copySelectedMedia = useSelector(state => state.input.copySelectedMedia);
  const customData = useSelector(state => state.input.customData);

  const dispatch = useDispatch();
  const groupCheckedArr = useSelector(state => state.input.groupCheckedArr);

  const onChangeGroupCheckbox = (e, groupName) => {
    const updatedCheckedState = media.map(item => {
      const newItem = { ...item };
      newItem.isChecked = e.currentTarget.checked;
      return item.group === groupName && item.CPP !== '' ? newItem : item;
    });
    // const updateCopySelectedMediaState = copySelectedMedia.map(item => {
    //   const newItem = { ...item };
    //   newItem.isChecked = e.currentTarget.checked;
    //   return item.group === groupName ? newItem : item;
    // });
    const updateGroupArrState = groupCheckedArr.map(group => {
      let newGroup = { ...group };
      newGroup[groupName] = e.currentTarget.checked;
      return Object.keys(group)[0] === groupName ? newGroup : group;
    });
    dispatch(setAllMedia(updatedCheckedState));
    // dispatch(setCopySelectedMedia(updateCopySelectedMediaState));

    const updateCustomData = customData.map(item => {
      const newItem = { ...item };
      newItem.isChecked = e.currentTarget.checked ? 1 : 0;
      return item.customMediaGroup === groupName && item.CPP !== '' ? newItem : item;
    });
    dispatch(setCustomData(updateCustomData));
    dispatch(setGroupCheckedArr(updateGroupArrState));
    dispatch(setMediaIsSelected(true));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
  };

  const handleOnChange = (e, mediaName, groupName) => {
    const updatedCheckedState = media.map(item => {
      const newItem = { ...item };
      newItem.isChecked = e.currentTarget.checked;
      return item.media === mediaName ? newItem : item;
    });
    // const updateCopySelectedMediaState = copySelectedMedia.map(item => {
    //   const newItem = { ...item };
    //   newItem.isChecked = !newItem.isChecked;
    //   return item.media === mediaName ? newItem : item;
    // });
    dispatch(setAllMedia(updatedCheckedState));
    // dispatch(setCopySelectedMedia(updateCopySelectedMediaState));

    const updateCustomData = customData.map(item => {
      const newItem = { ...item };
      newItem.isChecked = e.currentTarget.checked ? 1 : 0;
      return item.customMedia === mediaName ? newItem : item;
    });
    dispatch(setCustomData(updateCustomData));

    dispatch(setMediaIsSelected(true));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
  };
  const onChangeSrt = (newValue, mediaName) => {
    const updatedCheckedState = media.map(item => {
      const newItem = { ...item };
      newItem.CPP = newValue;
      return item.media === mediaName ? newItem : item;
    });
    dispatch(setAllMedia(updatedCheckedState));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
    dispatch(setIsMonthVisible(0));
    dispatch(setMonth('Июнь'));
  };
  const onChangeContactQuality = (newValue, mediaName) => {
    const updatedCheckedState = media.map(item => {
      const newItem = { ...item };
      newItem.coeff = newValue;
      return item.media === mediaName ? newItem : item;
    });
    dispatch(setAllMedia(updatedCheckedState));
    dispatch(setInputDataChanged(true));
    dispatch(setMainMedia(null));
    dispatch(setIsChartsUpload(false));
  };

  useEffect(() => {
    media.map(media => {
      const updateGroupArrState = groupCheckedArr.map(group => {
        let newGroup = { ...group };
        newGroup[media.group] = false;
        return Object.keys(group)[0] === media.group ? newGroup : group;
      });
      media.CPP !== '' && media.isChecked === false && groupCheckedArr.find(group => group[media.group]) && groupCheckedArr.find(group => group[media.group])[media.group] === true && dispatch(setGroupCheckedArr(updateGroupArrState));
      return media;
    });
  }, [media, dispatch, groupCheckedArr]);

  return (
    <div className={s.blockWrapper}>
      <div className={s.blockTitleStart}>ГРУППА</div>
      <div className={s.blockTitleGroup}>МЕДИА</div>
      <div className={s.blockTitleMiddle}>СРТ/СРМ</div>
      <div className={s.blockTitleEnd}>
        КАЧЕСТВО <br /> КОНТАКТА
      </div>

      <div className={s.blockContent}>
        {media &&
          media.map(media => {
            const groupName = groupCheckedArr.find(group => Object.keys(group)[0] === media.group);

            return (
              <div className={media.isChecked ? s.item : `${s.item} ${s.text_muted}`} key={media.media}>
                <div className={media.isChecked ? s.checkboxWrapper : `${s.checkboxWrapper} ${s.text_muted_for_checkbox}`}>
                  {firsMediaInGroupArr.find(mediaName => mediaName === media.media) ? (
                    <div className={s.groupCheckboxWrapper}>
                      <Checkbox
                        onChangeChecked={e => {
                          onChangeGroupCheckbox(e, media.group);
                        }}
                        checked={groupName[media.group]}
                        label={media.group}
                        id={media.group}
                      />
                    </div>
                  ) : (
                    <div className={s.groupCheckboxWrapper}></div>
                  )}
                  <div className={s.mediaCheckboxWrapper}>
                    <Checkbox
                      onChangeChecked={e => {
                        handleOnChange(e, media.media, media.group);
                      }}
                      checked={media.isChecked}
                      label={media.media}
                      id={`${media.media}+${media.media}`}
                      isDisabled={media.CPP === '' ? true : false}
                    />
                  </div>
                </div>
                <div className={s.editableSpanWrapper}>
                  <EditableSpan value={media.CPP} name={media.media} onChange={onChangeSrt} location={'mediaChoiceTable'} question info={findQuestion(media.group)} bold={media.isChecked ? 'bold' : ''} />
                </div>
                <div className={s.editableSpanWrapper2} style={{ marginLeft: '20px' }}>
                  <EditableSpan value={media.coeff} name={media.media} onChange={onChangeContactQuality} location={'mediaChoiceTableCoeff'} question info={media.group === 'TV' ? 'Качество контакта устанавливается пленером в зависимости от будущей кампании клиента на основе собственной экспертной оценки различных форматов. 100% качества для ТВ сейчас взято для 20 секунд' : 'Качество контакта устанавливается пленером в зависимости от будущей кампании клиента на основе собственной экспертной оценки различных форматов'} bold={media.isChecked ? 'bold' : ''} />
                </div>
              </div>
            );
          })}
      </div>
      <div className={s.prompt}>
        <div className={s.promptCRT}>
          <span style={{ color: '#d32f2f' }}>CPT</span> (1000 контактов){' '}
        </div>
        <div className={s.promptCPM}>
          <span style={{ color: '#d32f2f' }}>CPM</span> (1000 показов)
        </div>
      </div>
    </div>
  );
});
